import React, {
    useState,
    useImperativeHandle,
    forwardRef,
    useEffect,
} from 'react';
import s from './s.module.less';
import { Button, Descriptions, Form, Input, Modal, message } from 'antd';
import type { TAssessmentItem, TResult } from 'types/operation';
import commonS from 'styles/common.module.less';
import {
    assessmentResultLevelAddOrUpdate,
    assessmentResultLevelAddOrUpdateBatch,
    getAiGenerateData,
    getAssessmentResultLevel,
} from 'api/operation/assessment';
import ResultAnalysis from '../ResultAnalysis';
import cx from 'classnames';

type IProps = {
    isLoading: boolean;
    item: TAssessmentItem;
    onSuccess: (obj: Partial<TAssessmentItem>) => void;
};

export interface IResultPrompt {
    validateAndSubmit: () => boolean;
}

const ResultPrompt = forwardRef<IResultPrompt, IProps>((props, ref) => {
    const { item, onSuccess } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [form] = Form.useForm<{ active: boolean; prompt: string }>();
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState(false);
    const [list, setList] = useState<TResult[]>([] as TResult[]);
    const [aiPreviewList, setAiPreviewList] = useState<TResult[]>(
        [] as TResult[],
    );
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleEdit = () => {
        setIsShowModal(true);
    };

    const handleCancel = () => {
        setIsShowModal(false);
    };

    const getList = async () => {
        const { data } = await getAssessmentResultLevel({
            assessmentId: item.id || '',
        });
        setList(data.data);
        setAiPreviewList(data.data);
    };

    const handleTestAi = async () => {
        const formValues = await form.validateFields();
        setIsLoading(true);
        try {
            const { data } = await getAiGenerateData({
                prompt: formValues.prompt,
                assessmentId: item.id || '',
            });
            setIsLoading(false);
            if (data.data) {
                message.success('Successfully generated');
                setAiPreviewList(data.data);
            } else {
                message.error('Failed to generate, please try again');
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const onSavePrompt = async () => {
        const formValues = await form.validateFields();
        try {
            const params = {
                assessmentId: item.id || '',
                promptManagement: formValues.prompt,
                reqs: aiPreviewList,
                analyzingPrompt: item.analyzingPrompt,
            };
            const { data } = await assessmentResultLevelAddOrUpdateBatch(
                params,
            );

            if (!data.error) {
                setIsShowModal(false);
                message.success('Updated successfully');
                onSuccess(params);
            }
        } catch (err) {}
    };

    useEffect(() => {
        if (item.id) {
            getList();
        }
    }, [item]);

    const renderDescription = () => {
        return (
            <Descriptions
                colon={false}
                title="Prompt management"
                layout="vertical"
                column={1}
                size="small"
                contentStyle={{
                    marginBottom: '16px',
                }}
            >
                <Descriptions.Item
                    label={
                        <>
                            Result category copy
                            <Button onClick={() => handleEdit()} className={s.btn}>Edit</Button>
                        </>
                    }
                >
                    {item.promptManagement || '-'}
                </Descriptions.Item>
            </Descriptions>
        );
    };

    const renderCard = ({
        riskLevel,
        title,
        description,
    }: TResult) => {
        const cardTitle = ['Low indication', 'Moderate indication', 'High indication'];
        return (
            <div className={s.card}>
                <Descriptions
                    colon={false}
                    title={cardTitle[riskLevel - 1]}
                    layout="vertical"
                    column={1}
                    size="small"
                    contentStyle={{
                        marginBottom: '16px',
                    }}
                >
                    <Descriptions.Item label="Title">
                        {title || '-'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Description">
                        {description || '-'}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        );
    };

    useImperativeHandle(ref, () => ({
        validateAndSubmit: () => {
            return !isEdit;
        },
    }));

    useEffect(() => {
        if (item) {
            form.setFieldsValue({
                prompt: item.promptManagement,
            });
        } else {
            form.resetFields();
        }
    }, [item]);

    return (
        <div className={s.wrap}>
            <div className={cx(s.content, s.analysisPrompt)}>
                {renderDescription()}
                <ResultAnalysis item={item} onSuccess={onSuccess} />
            </div>
            <Modal
                title="Prompt management"
                open={isShowModal}
                destroyOnClose
                okText="Save"
                onCancel={handleCancel}
                width={1000}
                footer={
                    <div>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            loading={submitting}
                            type="primary"
                            onClick={onSavePrompt}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <div className={s.wrap}>
                    <div className={s.left}>
                        <Form
                            form={form}
                            className={commonS.formStyle1}
                            initialValues={{ active: true }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <Form.Item
                                className={s.rowItem}
                                label="Result category copy"
                                name="prompt"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Result category copy is required',
                                    },
                                ]}
                            >
                                <Input.TextArea maxLength={1000} rows={20} showCount />
                            </Form.Item>
                        </Form>
                        <Button onClick={handleTestAi} loading={isLoading}>
                            Test
                        </Button>
                    </div>
                    <div className={s.right}>
                        {aiPreviewList.map((aiItem) => {
                            return renderCard({
                                riskLevel: aiItem.riskLevel,
                                title: aiItem.title,
                                description: aiItem.description,
                            });
                        })}
                    </div>
                </div>
            </Modal>
            <Modal
                title="Prompt management"
                open={isShowModal}
                destroyOnClose
                okText="Save"
                onCancel={handleCancel}
                width={1000}
                footer={
                    <div>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button
                            loading={submitting}
                            type="primary"
                            onClick={onSavePrompt}
                        >
                            Save
                        </Button>
                    </div>
                }
            >
                <div className={s.wrap}>
                    <div className={s.left}>
                        <Form
                            form={form}
                            className={commonS.formStyle1}
                            initialValues={{ active: true }}
                            autoComplete="off"
                            layout="vertical"
                            scrollToFirstError
                        >
                            <Form.Item
                                className={s.rowItem}
                                label="Result category copy"
                                name="prompt"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Result category copy is required',
                                    },
                                ]}
                            >
                                <Input.TextArea maxLength={500} rows={20} showCount />
                            </Form.Item>
                        </Form>
                        <Button onClick={handleTestAi} loading={isLoading}>
                            Test
                        </Button>
                    </div>
                    <div className={s.right}>
                        {aiPreviewList.map((aiItem) => {
                            return renderCard({
                                riskLevel: aiItem.riskLevel,
                                title: aiItem.title,
                                description: aiItem.description,
                            });
                        })}
                    </div>
                </div>
            </Modal>
        </div>
    );
});

export default ResultPrompt;
